export const DealStrategies = [
	{
		'code': 'Development',
		'name': 'Development'
	},
	{
		'code': 'Acquisition',
		'name': 'Acquisition'
	},
	{
		'code': 'Adaptive Reuse',
		'name': 'Adaptive Reuse'
	}
];
