import Joi from 'joi';
import { PropertyContactRoles } from '../../../constants/contact-roles.js';

export const UpdatePropertyContactSchema = Joi.object({
	id: Joi.number().optional().label('Id'),
	property: Joi.object({
		id: Joi.number().required().label('Community Id')
	}).required(),
	contact: Joi.object({
		externalId: Joi.string().required().label('Contact Id'),
		email: Joi.string().required().label('Contact email'),
		name: Joi.string().required().label('Contact name')
	}).required(),
	role: Joi.string()
		.required()
	//	.valid(...PropertyContactRoles.flatMap(g=>g.roles.map(r=>r)))
		.label('Role'),
})
	.required()
	.label('Community Contact');
