import Joi from 'joi';
import { DealStrategies } from '../../constants/deal-strategies.js';
import { States } from '../../constants/states.js';
import {LinksObject} from "../links.js";

const externalProperty = {
	id: Joi.number().required().label('Entrata PropertyId'),
	marketingName: Joi.string().optional().label('Entrata Marketing Name')
};

export const PropertySchema = Joi.object({
	stateCode: Joi.string()
	.optional()
	.label('State')
	.allow(...States.map(v => v.code)),
	city: Joi.string()
	.optional()
	.disallow('')
	.label('City'),
	streetAddress: Joi.string()
	.optional()
	.disallow('')
	.label('Address'),
	postalCode: Joi.string()
	.optional()
	.allow('', null)
	.regex(/^\d{5}([\-]?\d{4})?$/, '#### or #####-####')
	.label('PostalCode'),
	phone: Joi.string()
		.optional()
		.allow('', null)
		.regex(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, '###-###-####')
		.label('Phone'),
	name: Joi.string()
	.optional()
	.disallow('')
	.label('Name'),
	alias: Joi.string()
	.optional()
	.allow('', null)
	.label('Nickname'),
	// owned: Joi.boolean().optional().label('Owned'),
	// managed: Joi.boolean().optional().label('Managed'),
	active: Joi.boolean().optional().label('Active'),
	operatingEntityName: Joi.string().optional()
		.allow(null, '')
		.empty('').default(null)
		.label('Operating Entity Name'),
	fein: Joi.string()
		.optional()
		.allow(null,'')
		.regex(/^$|^[1-9]\d?-\d{7}$/, '##-#######')
		.label('FEIN'),
	externalProperty: Joi.object(externalProperty)
	.optional()
	.allow(null)
	.label('Entrata Community'),
	marketingName: Joi.string()
	.optional()
	.allow('', null)
	.label('Marketing Name'),
	propertyTypeId: Joi.number().optional().label('Community Type Id'),
	propertyType: Joi.object({
		id: Joi.number().required().label(' Community Type Id'),
		name: Joi.string().optional().label('Community Type Name'),
		index: Joi.number().optional().label('Community Type Index'),
	}).allow(null).optional().label('Community Type'),
	buildingCount: Joi.number()
		.optional()
		.allow(null)
		.label('Buildings'),
	unitCount: Joi.number()
		.optional()
		.allow(null)
		.label('Units'),
	bedCount: Joi.number()
		.optional()
		.allow(null)
		.label('Beds'),
	parkingSpaceCount: Joi.number()
		.optional()
		.allow(null)
		.label('Parking Spaces'),
	grossArea: Joi.number()
		.optional()
		.allow(null)
		.label('Gross SF'),
	residentialArea: Joi.number()
		.optional()
		.allow(null)
		.label('Residential SF'),
	retailArea: Joi.number()
		.optional()
		.allow(null)
		.label('Retail SF'),
	market: Joi.object({
		id: Joi.number()
			.required()
			.label('Id'),
		name: Joi.string()
			.optional()
			.label('Name'),
		stateCode: Joi.string().optional().label('State'),
		universities: Joi.array().optional().label('Universities')
	}).optional().allow(null),
	university: Joi.object({
		id: Joi.number()
		.required()
		.label('Id'),
		name: Joi.string()
		.optional()
		.label('Name')
	}).optional().allow('', null),
	totalValue: Joi.number()
		.optional()
		.allow(null)
		.label('Total Value'),
	projectedCost: Joi.number()
		.optional()
		.allow(null)
		.label('Projected Cost'),
	actualCost: Joi.number()
		.optional()
		.allow(null)
		.label('Actual Cost'),
	dealStrategy: Joi.string()
		.optional()
		.allow(null)
		.label('Deal Strategy')
		.allow('', ...DealStrategies.map(v => v.code)),
	phaseId: Joi.number().optional().label('Phase'),
	groupId: Joi.number().optional().label('Group'),
	group: Joi.object({
		id: Joi.number().required().label(' Group Id'),
		name: Joi.string().optional().label('Group Name'),
	}).allow(null).optional().label('Group'),
	propertyContacts: Joi.optional().allow(null, Joi.object()).label('Contacts'),
	phase: Joi.object({
		id: Joi.number().required().label('Id'),
		name: Joi.string().optional().label('Name'),
		code: Joi.string().optional().label('Code'),
		index: Joi.number().optional().label('Index')
	}).optional().label('Phase'),
	equityPartners: Joi.array().optional()
		.allow(null).label('Equity Partners'),
	ownedBy: Joi.array().optional()
		.allow(null).label('Owned By'),
	managedBy: Joi.array().optional()
		.allow(null).label('Managed By'),
	yearBuilt: Joi.number().optional().allow(null).label('Year Built'),
	links: LinksObject
})

export const UpdatePropertySchema = Joi.object({
	id: Joi.any()
		.required()
		.label('Id'),
}).concat(PropertySchema)
	.required()
	.label('Community');
