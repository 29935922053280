import {
	useIsAuthenticated,
	useMsal
} from '@azure/msal-react';
import {
	LookerEmbedDashboard,
	LookerEmbedFilterParams,
	PageChangedEvent,
	UrlParams
} from '@looker/embed-sdk';
import { Button } from '@mui/material';

// @ts-ignore


import { useSnackbar } from 'notistack';
import React, {
	forwardRef,
	useEffect,
	useRef,
	useState
} from 'react';
import { reAuth } from '../../services/common/api-utils';
import { useLooker } from '../hooks/LookerEmbed';

import '../../styles/looker.css';
type PropsType = {
	dashboardId: number | string
	params: UrlParams,
	filters: LookerEmbedFilterParams,
	callback: any,
	onPageChanged: (this:any, event:PageChangedEvent)=>void,
	onDashboardLoaded:  (dashboard:LookerEmbedDashboard)=>void

}

export const Dashboard = forwardRef<HTMLDivElement, PropsType>( ( props: PropsType, fwRef ) => {
	const msalContext = useMsal()
	const dashboardId = props.dashboardId;
	const params = props.params;
	const filters = props.filters;
	const callback = props.callback;
	const onPageChanged = props.onPageChanged;
	const onDashboardLoaded = props.onDashboardLoaded;
	const ref = useRef<HTMLDivElement | null>( null );


	const { enqueueSnackbar } = useSnackbar();
	const [ iFrameData ] = useState( {
		id: 'looker_dashboard_holder_' + dashboardId,
		holderId: 'looker_dashboard_holder_' + dashboardId,
		classes: 'looker_dashboard'
	} );

	const pageChanged = ( pageData: any ) => {
		const iframe = ref.current?.getElementsByTagName( 'iframe' )?.[0];
		if ( iframe ) {
			iframe.height = pageData.height;
		}
	};

	useEffect( () => {
		const el = ref!.current;
		if ( el && dashboardId ) {
			el.innerHTML = '';
			useLooker( msalContext )
				.then( it => it.createDashboardWithId( dashboardId )
				               .on( 'page:properties:changed', pageChanged )
				               .on( 'page:changed', onPageChanged )
				               .on( 'dashboard:loaded', onDashboardLoaded )
				               .withNext()
				               .appendTo( el )
				               .withFilters( filters )
				               .withParams( params )
				               .build()
				               .connect()
				               .then( callback )
				               .catch( ( error ) => {
					               console.error( 'An unexpected error occurred', error );
					               const message = `An error [${ error }] occurred retrieving report.  This may be due to a session timeout.  Please Sign out and try again.`;
					               enqueueSnackbar( message, {
						               variant: 'warning',
						               anchorOrigin: {
							               vertical: 'top',
							               horizontal: 'center'
						               },
						               action: () => <Button onClick={ event => reAuth( msalContext ) }> Retry </Button>

					               } );
				               } ) );
		}
	}, [ dashboardId, msalContext ] );

	return ( <div style={ { height: '100%' } } ref={ ref } id={ iFrameData.holderId }/> )
})
