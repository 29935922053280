import Joi from 'joi';
import { VendorTypes } from '../../../constants/vendor-types.js';
const types = VendorTypes.map(t=>t.type);
export const UpdatePropertyVendorSchema = Joi.object({
	id: Joi.number().optional().label('Id'),
	property: Joi.object({
		id: Joi.number().required().label('Community Id')
	}).required(),
	vendor: Joi.array().required(),
	type: Joi.string()
		.required()
		.valid(...types)
		.label('Vendor Type'),
})
	.required()
	.label('Community Vendor');
