import Joi from 'joi';
import {tlds} from "@hapi/tlds";

export const MapUri = Joi.string()
	.optional()
	.allow(null, '')
	.uri({
		scheme: [
			'https',
		],
		domain: {
			tlds: {
				allow: tlds
			}
		}
	})
	.regex(/\/\/google.com\/maps|\/\/www.google.com\/maps|\/\/goo.gl\/maps\//)

export const DocsUri = Joi.string()
	.optional()
	.allow('')
	.uri({
		scheme: [
			'https',
		],
		domain: {
			tlds: {
				allow: tlds
			}
		}
	})
	.regex(/\/\/docs.google.com/)


export const LinkUri = Joi.string().allow(null).uri({
	scheme: [
		'https',
	],
	domain: {
		tlds: {
			allow: tlds
		}
	}
})
	.regex(/\/\/google.com\/|\/\/.*.google.com\/|\/\/app.clickup.com\//)
export const LinksObject = Joi.object()
	.optional()
	.allow(null, '')
	.pattern(
		Joi.string(),
		LinkUri
	)
