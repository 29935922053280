import {
	AuthenticationResult,
	InteractionStatus
} from '@azure/msal-browser';
import {
	useIsAuthenticated,
	useMsal
} from '@azure/msal-react';
import {
	AbilityTuple,
	MongoAbility,
	MongoQuery
} from '@casl/ability';
import { unpackRules } from '@casl/ability/extra';
import React, {
	useContext,
	useEffect
} from 'react';
import { AbilityContext } from '../components/AbilityContext';
import {
	UserApi,
	useUserApi
} from '../services/user-api';
import { getTokenRequest } from '../utils/authConfig';


export const updateAbilityFromRoles = async ( roles: string[],
                                              ability: MongoAbility<AbilityTuple, MongoQuery>,
                                              api: UserApi ) => {
	console.debug('updateAbilityFromRoles', { roles })
	return await api.getPermissionsByRoles( roles ?? [] )
	   .then( ( result ) => {
		   const { permissions } = result;
		   console.debug('updateAbilityFromRoles', { permissions })
		   ability.update( unpackRules( permissions ?? [] ) );
		   return permissions
	   } );
};

function processToken( ability: MongoAbility<AbilityTuple, MongoQuery>, api: UserApi ) {
	return ( result: AuthenticationResult ) => {
		console.debug( 'AuthorizedRoute', { result } );
		const { account } = result;
		const { roles } = account.idTokenClaims ?? {};
		updateAbilityFromRoles( roles ?? [], ability, api )
			.then( () => {
				console.debug( 'AuthorizedRoute updated current abilities with roles from token' );
			} );
	};
}

const AuthorizedRoute = ( _ref: any ) => {
	const { children, } = _ref;
	const isAuthenticated = useIsAuthenticated();
	const { instance, accounts, inProgress } = useMsal();
	const ability: MongoAbility = useContext( AbilityContext );
	const api = useUserApi();
	useEffect( () => {
		if ( isAuthenticated && inProgress === InteractionStatus.None ) {
			console.info( `AuthorizedRoute`, { accounts } );
			if ( ability.rules.length === 0 ) { //?
				const { roles } = accounts[0]?.idTokenClaims ?? {};
				if ( roles && roles.length > 0 ) {
					updateAbilityFromRoles( roles, ability, api )
						.then( () => {
							console.debug( 'AuthorizedRoute updated current abilities with roles from token' );
						} );
				}
				else {
					instance.acquireTokenSilent( getTokenRequest( accounts ) )
					        .then( processToken( ability, api ) )
					        .catch( ( error ) => {
						        if ( isAuthenticated && inProgress === InteractionStatus.None ) {
							        return instance
								        .acquireTokenPopup( getTokenRequest( accounts ) )
								        .then( processToken( ability, api ) )
								}
					        } );
				}
			}
		}
	}, [ instance, accounts ] );
	return <>
		{ children }
	</>;
};

export default AuthorizedRoute;
