import Joi from 'joi';

const RentProjectionSchema= Joi.object({
	id: Joi.any().required().label('Id is required for some reason'),
	numberOfBeds: Joi.number().required().label('Number of Beds'),
	futureRent: Joi.number().required().label('Future Rent Value'),
	percentIncrease: Joi.number().required().label('Percent Increase'),
	plannedDiscounts: Joi.number().required().label('Planned Discounts')
})

export const UpdatePropertySpaceOptionSchema = Joi.object({
	id: Joi.number().optional().label('Id'),
	spaceOption: Joi.string().required().label('Space Option'),
	bedCount: Joi.number().required().label('Bed Count'),
	currentRent: Joi.number().optional().allow(null).label('Current Rent'),
	budgetRent: Joi.number().required().label('Budget Rate'),
	budgetYear: Joi.string().required().label('Budget Year'),
	unitTypeId: Joi.number().required().label('Unit Type Id'),
	projectedRents: Joi.array().items(RentProjectionSchema),
});

export const AddPropertyBudgetYearSchema = Joi.object({
	year: Joi.number().required().label('Year'),
	property: Joi.object({
		id: Joi.number().required().label('Community Id'),
	}).required(),
});
