import Joi from 'joi';
import { VendorTypes } from '../../../constants/vendor-types.js';
const types = VendorTypes.map(t=>t.type);

export const CreatePropertyVendorSchema = Joi.object({
	property: Joi.object({
		id: Joi.number().required().label('Community Id')
	}).optional(),
	vendor: Joi.array().items(
		Joi.object({
			id: Joi.number().required().label('Vendor Id'),
			name: Joi.string().optional().label('Vendor Name')
		})
	),
	type: Joi.string()
		.required()
		.valid(...types)
		.label('Vendor Type'),
})
	.required()
	.label('Community Contact');
