
const STUDENT = 1;
const CONVENTIONAL_BTR = 2;
const CONVENTIONAL_MULTIFAMILY = 3;
const STUDENT_CONVENTIONAL_LEASING = 4;

const _values = {
	STUDENT,
	CONVENTIONAL_BTR,
	CONVENTIONAL_MULTIFAMILY,
	STUDENT_CONVENTIONAL_LEASING
}
export const PropertyTypes = {
	..._values,
	values: ()=>Object.values(_values),
	conventional: ()=>[CONVENTIONAL_BTR, CONVENTIONAL_MULTIFAMILY],
	student: ()=>[STUDENT, STUDENT_CONVENTIONAL_LEASING]
}
