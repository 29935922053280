export const VendorTypes = [
	{type: 'ARCHITECT', label: 'Architect'}, //project
	{type: 'EQUITY_PARTNER', label: 'Equity Partner'}, //property
	{type: 'GENERAL_CONTRACTOR', label: 'General Contractor'},//project
	{type: 'LENDER', label: 'Lender'}, //project
	{type: 'INTERIOR_DESIGNER', label: 'Interior Designer' }, //project
	{type: 'CO_DEVELOPER', label: 'Co-Developer' },
	{type: 'OWNER', label: 'Owner' },
	{type: 'MANAGER', label: 'Manager' },
];
