import Joi from 'joi';
import { States } from '../../constants/states.js';

const externalProperty = {
	id: Joi.number().required().label('Entrata PropertyId'),
	marketingName: Joi.string().optional().label('Entrata Marketing Name')
};

export const CreatePropertySchema = Joi.object({
	id: Joi.any()
		.optional()
		.label('Id'),
	stateCode: Joi.string()
		.required()
		.label('State')
		.allow(...States.map(v => v.code)),
	city: Joi.string()
		.required()
		.disallow('')
		.label('City'),
	streetAddress: Joi.string()
		.required()
		.disallow('')
		.label('Address'),
	postalCode: Joi.string()
		.optional()
		.allow(null,'')
		.regex(/^$|^\d{5}([\-]?\d{4})?$/, '#### or #####-####')
		.label('PostalCode'),
	phone: Joi.string()
		.optional()
		.allow('', null)
		.regex(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, '###-###-####')
		.label('Phone'),
	name: Joi.string()
		.required()
		.disallow('')
		.label('Name'),
	alias: Joi.string()
		.optional()
		.allow('')
		.label('Nickname'),
	owned: Joi.boolean().optional().label('Owned'),
	managed: Joi.boolean().optional().label('Managed'),
	active: Joi.boolean().optional().label('Active'),
	operatingEntityName: Joi.string().optional()
		.empty('').default(null)
		.label('Operating Entity Name'),
	fein: Joi.string()
		.optional()
		.allow(null, '')
		.empty('').default(null)
		.regex(/^$|^[1-9]\d?-\d{7}$/, '##-#######')
		.label('FEIN'),
	externalProperty: Joi.object(externalProperty).optional().allow(null).label('Entrata Community'),
	marketingName: Joi.string()
		.optional()
		.allow('')
		.label('Marketing Name'),
	propertyTypeId: Joi.number().optional().label('Community Type Id'),
	propertyType: Joi.object({
		id: Joi.number().required().label(' Community Type Id'),
		name: Joi.string().optional().label('Community Type Name'),
		index: Joi.number().optional().label('Community Type Index'),
	}).allow(null).optional().label('Community Type'),
	buildingCount: Joi.number()
		.optional()
		.label('Buildings'),
	unitCount: Joi.number()
		.optional()
		.label('Units'),
	bedCount: Joi.number()
		.optional()
		.label('Beds'),
	parkingSpaceCount: Joi.number()
		.optional()
		.label('Parking Spaces'),
	grossArea: Joi.number()
		.optional()
		.label('Gross SF'),
	residentialArea: Joi.number()
		.optional()
		.label('Residential SF'),
	retailArea: Joi.number()
		.optional()
		.label('Retail SF'),
	market: Joi.object({
		id: Joi.number()
			.required()
			.label('Id'),
		name: Joi.string()
			.optional()
			.label('Name'),
		stateCode: Joi.string().optional().label('State'),
		universities: Joi.array().optional().label('Universities')
	}).optional().allow(null),
	university: Joi.object({
		id: Joi.number()
			.required()
			.label('Id'),
		name: Joi.string()
			.optional()
			.label('Name')
	}).optional().allow('', null),
	totalValue: Joi.number()
		.optional()
		.label('Total Value'),
	equityPartners: Joi.array().optional()
		.allow(null).label('Equity Partners'),
})
	.required()
	.label('Community');
