
const Acquisitions = {
	name:'Acquisitions',
	roles: [
		'Acquisition Lead',
		'Acquisition U/W Lead',
		'Entitlement Lead',
	]
}
const Development = {
	name:'Development',
	roles: [
		'Development Manager',
		'Associate Development Manager',
		'Development Director'
	]
}
const Finance = {
	name:'Finance',
	roles:[
		'Community Accountant',
		'Project Accountant',
		'Fund Accountant',
		'Accounting Manager',
		'Fund Accounting Manager',
		'Debt Capital Markets'
	]
}

const PropertyManagement = 	{
	name: 'Community Management',
	roles: [
		'Community Manager',
		'Associate Community Manager',
		'Sales Manager',
		'Revenue Manager',
		'Engineering Manager',
		'Community Leasing Manager',
		'Regional Manager',
		'Regional Engineering Manager',
		'Director of Community Management',
		'Centralized Account Manager'
	]
}

const AssetManagement = {
	name: 'Asset Management',
	roles:[
		'Asset Manager',
		'Assistant Asset Manager',
		'Asset Management Director'
	]
}

export const PropertyContactRoles = [
	Acquisitions,
	Development,
	AssetManagement,
	Finance,
	PropertyManagement
];

export const ProjectContactRoles = [
	Acquisitions,
	Development,
	Finance
];
