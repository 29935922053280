import {
	Link,
	MenuItem,
} from '@mui/material';
import {
	differenceInDays,
	format,
} from 'date-fns';
import numbro from 'numbro';
import React, { useMemo } from 'react';
import {
	getGridDefaultColumnTypes,
	GridColDef,
	GridGroupingValueGetterParams,
	GridRenderCellParams,
	GridSortCellParams,
	gridStringOrNumberComparator,
	GridTreeNodeWithRender,
	GridValueFormatterParams,
	GridValueGetterParams
} from '@mui/x-data-grid-premium';
import { Link as RouterLink } from 'react-router-dom';
import { getPreviousThursday } from '../../utils';
import {
	NamedObjectArrayComparator,
	NamedObjectComparator,
	ObjectIdComparator
} from '../grid/GridColumnComparator';
import { NamedObjectFilter } from '../grid/GridColumnFilterWrapper';
import useGridHelper from '../hooks/GridHelper';
import useIntlPlus from '../hooks/IntlPlus';
import InputField from '../InputField';
import { Contact } from 'components/User/types';
import Select from '../Select';
import { Vendor } from '../Vendor/types';
import { Watchlist } from './types';
import { InferPropsInner } from 'prop-types';

interface WatchlistProps {
	watchlist?: Watchlist;
}

function useWatchlistPropertiesGridColumns( props: WatchlistProps ) {
	const { watchlist } = props;
	const watchListStateMap = useMemo(()=>watchlist?.config?.states?.reduce(
		( acc: { [key: string]: number }, str: string, index: number ) => {
			acc[str] = index;
			return acc;
		}, {}
	),[watchlist?.config?.states]) ?? {};
	const intl = useIntlPlus();
	const defaultColumnTypes = getGridDefaultColumnTypes();
	const { valueGetter, formatDate, formatDateTime, renderComment, stringToNumberValueGetter } = useGridHelper();
	const formatUser = ( params: GridValueFormatterParams ) => {
		if ( !params.value ) {
			return params.value;
		}
		return params.value.name;
	};
	const getVendorNames = ( p: GridValueGetterParams ) => {
		return p?.value?.map( ( pv: Vendor ) => pv?.name );
	};
	const formatNumberAsPercent = ( props:GridValueFormatterParams ) => props.value && numbro( props.value ).format( '#,##0.0%' );
	const commentFormatter = (({ value }: GridValueFormatterParams)=>value?.split('\n').map((line:string)=>value.startsWith('-') || value.startsWith('*') ? '•' + line.slice(1) : line).join('\n'));

	return useMemo<GridColDef[]>( () => {
		const watchlistStateSortComparator = (
			v1: string | number,
			v2: string | number,
			v1CellParams: GridSortCellParams<any>,
			v2CellParams: GridSortCellParams<any>
		) => {
			const v1Order = watchListStateMap?.[v1] ?? 100;
			const v2Order = watchListStateMap?.[v2] ?? 100;
			return gridStringOrNumberComparator( v1Order, v2Order, v1CellParams, v2CellParams );
		};
		const PERCENT_AS_STRING_FIELD_PROPS: Partial<GridColDef> = {
			valueGetter: stringToNumberValueGetter,
			type: 'number',
			minWidth: 100,
			valueFormatter: formatNumberAsPercent
		};

		const COMMENT_FIELD_PROPS: Partial<GridColDef> = {
			minWidth: 300,
			renderCell: renderComment,
			type:'string',
			valueGetter,
			valueFormatter: commentFormatter
		};

		return [
			{
				field: 'name',
				headerName: intl.formatMessage( { id: 'property', defaultMessage: 'Community' } ),
				minWidth: 200,
				description: intl.formatMessage( { id: 'property_name', defaultMessage: 'Community Name' } ),
				editable: true,
				readOnly: true,
				filterOperators: defaultColumnTypes['string']?.filterOperators?.map( o => NamedObjectFilter( o ) ),
				sortComparator: NamedObjectComparator,
				renderCell: ( params: {
					row: { id: any; };
					value: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined;
				} ) => (
					<Link component={ RouterLink } to={ `/communities/${ params.row.id }` }>{ params.value }</Link>
				),
				renderEditCell: ( params: React.JSX.IntrinsicAttributes & InferPropsInner<Pick<any, never>> & Partial<InferPropsInner<Pick<any, string | number | symbol>>> ) => (
					<InputField { ...params } defaultValue={ params.row.name } /> ),
			},
			{
				field: 'watchlist.state',
				headerName: intl.formatMessage( {
					id: 'watchlist.state.headerName',
					defaultMessage: 'Watchlist State'
				} ),
				description: intl.formatMessage( {
					id: 'watchlist.state.description',
					defaultMessage: 'Current state of watchlist monitoring'
				} ),
				editable: true,
				minWidth: 160,
				type: 'string',
				valueGetter: (params: GridValueGetterParams)=>valueGetter(params) ?? watchlist?.config?.states?.at(0),
				groupingValueGetter: ( params: GridValueGetterParams<any, any, GridTreeNodeWithRender> ) => valueGetter(
					params as GridValueGetterParams ) ?? watchlist?.config?.states?.at(0),
				sortComparator: watchlistStateSortComparator,
				//@ts-ignore
				renderEditCell: ( params ) => <Select
					{ ...params }
					options={ watchlist?.config?.states ?? [] }
					renderOption={ ( option: string ) => (
						<MenuItem key={ option } value={ option }>{ option }</MenuItem> ) }
				/>
			},
			{
				field: 'market', headerName: intl.formatMessage( { id: 'property_market', defaultMessage: 'Market' } ),
				description: 'Market or Metro area of Community',
				minWidth: 120,
				editable: false,
				valueFormatter: ( params: GridValueFormatterParams ) => params.value?.name,
				groupingValueGetter: ( params: GridGroupingValueGetterParams ) => params.value?.name,
				filterOperators: defaultColumnTypes['string'].filterOperators?.map( o => NamedObjectFilter( o ) ),
				sortComparator: NamedObjectComparator,
			},
			{
				field: 'propertyGroups',
				headerName: intl.formatMessage( { id: 'group', defaultMessage: 'Reporting Group' } ),
				minWidth: 200,
				description: 'Asset Reporting Group',
				editable: false,
				valueGetter: ( p: GridValueGetterParams ) => {
					let propertyPropertyGroupCurrent = Array.isArray( p.row?.propertyGroups ) ? p.row?.propertyGroups[0] : undefined;
					return propertyPropertyGroupCurrent?.propertyGroup?.name;
				},
				groupingValueGetter: ( p: GridGroupingValueGetterParams ) => {
					let propertyPropertyGroupCurrent = Array.isArray( p.row?.property?.propertyGroups ) ? p.row?.property?.propertyGroups[0] : undefined;
					return propertyPropertyGroupCurrent?.propertyGroup?.name;
				},
				filterOperators: defaultColumnTypes['string'].filterOperators?.map( o => NamedObjectFilter( o ) ),
				sortComparator: ObjectIdComparator,
			},
			{
				field: 'unitCount',
				headerName: '# Units',
				type: 'number',
				valueGetter,
			},
			{
				field: 'bedCount',
				headerName: '# Beds',
				type: 'number',
				valueGetter,
			},
			{
				field: 'propertyContacts[0].contact',
				headerName: 'Community Manager',
				valueGetter,
				valueFormatter: (params: GridValueFormatterParams<Contact>) => params?.value?.name,
				filterOperators: defaultColumnTypes['string'].filterOperators?.map( o => NamedObjectFilter( o ) ),
				sortComparator: NamedObjectComparator,
			},
			{
				field: 'equityPartners', headerName: 'Equity Partners', minWidth: 200,
				editable: false,
				description: 'Equity Partners invested in Community',
				valueFormatter: getVendorNames,
				renderCell: ( params: GridValueFormatterParams ) => params.value?.map((p:Vendor)=>p ? <Link component={ RouterLink } to={ `/partners/${ p.id }` }>{p.name}</Link> : null),
				filterOperators: defaultColumnTypes['string'].filterOperators?.map( o => NamedObjectFilter( o ) ),
				sortComparator: NamedObjectArrayComparator,
				groupingValueGetter: getVendorNames
			},
			{
				field: 'watchlist.reason',
				headerName: intl.formatMessage( { id: 'watchlist.state.headerName', defaultMessage: 'Main Concern' } ),
				description: intl.formatMessage( {
					id: 'watchlist.state.description',
					defaultMessage: 'Main Reason for addition to watchlist'
				} ),
				editable: true,
				width: 160, type: 'string',
				valueGetter,
				//@ts-ignore
				renderEditCell: ( params ) => <Select
					{ ...params }
					options={ watchlist?.config?.reasons ?? [] }
					renderOption={ ( option: string ) => (
						<MenuItem key={ option } value={ option }>{ option }</MenuItem> ) }
				/>
			},
			{
				field: 'latestStatus.data.watchlistComment',
				headerName: intl.formatMessage( {id: 'watchlistComment', defaultMessage: 'Watchlist Status' } ),
				...COMMENT_FIELD_PROPS
			},
			/** latest status data **/
			{
				field: 'latestStatus.data.lender',
				headerName: 'Lender',
				valueGetter,
			},
			{
				field: 'latestStatus.data.partner',
				headerName: 'Partner',
				valueGetter,
			},
			{
				field: 'retailArea',
				headerName: 'Retail SqFt',
				type: 'number',
				valueGetter,
			},
			{
				field: 'latestStatus.date',
				headerName: 'Status Date',
				type: 'date',
				valueFormatter: formatDate,
				valueGetter,
				renderCell: ( params: GridRenderCellParams ) => {
					const { value, formattedValue } = params; //publishedAt
					const lastReportDate = getPreviousThursday( new Date() );
					let stale = true;
					if ( lastReportDate && value ) {
						stale = differenceInDays( lastReportDate, value ) > 0;
					}
					return formattedValue && stale ?
						<span
							style={ { color: 'red' } }
							title={ `Weekly Update is stale.  Should be ${ format( lastReportDate, 'MM/dd/yyyy' ) }` }>
							{ formattedValue }
						</span> : formattedValue;
				},
			},
			// {
			// 	field: 'latestStatus.state',
			// 	headerName: intl.formatMessage( { id: 'latestStatus.state', defaultMessage: 'Status' }),
			// 	minWidth: 120,
			// 	renderCell: ( { value }: GridRenderCellParams ) => <ReportStateChip state={ value } />,
			// 	valueGetter,
			// },
			{
				field: 'latestStatus.data.rating',
				headerName: 'Rating',
				type: 'number',
				valueGetter,
			},
			// {
			// 	field: 'latestStatus.data.occupancy',
			// 	headerName: 'Occupancy',
			// 	type: 'number',
			// 	valueGetter,
			// },
			/** latestStatus Metrics **/
			{
				field: 'latestStatus.data.cyPreleasePercentages[0][\'CY %\']',
				headerName: 'CY %',
				...PERCENT_AS_STRING_FIELD_PROPS,
			},
			{
				field: 'latestStatus.data.cyPreleasePercentages[0][\'CY New %\']',
				headerName: 'CY New %',
				...PERCENT_AS_STRING_FIELD_PROPS,
			},
			{
				field: 'latestStatus.data.cyPreleasePercentages[0][\'CY Renewal %\']',
				headerName: 'CY Renewal %',
				...PERCENT_AS_STRING_FIELD_PROPS,
			},
			{
				field: 'latestStatus.data.cyPreleasePercentages[0][\'Delta to PY%\']',
				headerName: 'Delta to PY%',
				...PERCENT_AS_STRING_FIELD_PROPS,
			},
			{
				field: 'latestStatus.data.cyPreleaseNumbers[0][\'CY New\']',
				headerName: 'CY New',
				type: 'number',
				valueGetter,
			},
			{
				field: 'latestStatus.data.cyPreleaseNumbers[0][\'CY Leased\']',
				headerName: 'CY Leased',
				type: 'number',
				valueGetter,
			},
			{
				field: 'latestStatus.data.cyPreleaseNumbers[0][\'# Renewals\']',
				headerName: '# Renewals',
				type: 'number',
				valueGetter,
			},
			{
				field: 'latestStatus.data.cyPreleaseNumbers[0][\'Delta to Comp%\']',
				headerName: 'Delta to Comp%',
				...PERCENT_AS_STRING_FIELD_PROPS,
			},
			{
				field: 'latestStatus.data.weeklyLeasingStats1[0][\'Tours\']',
				headerName: 'Tours',
				type: 'number',
				valueGetter,
			},
			{
				field: 'latestStatus.data.weeklyLeasingStats1[0][\'Total Leases\']',
				headerName: 'Total Leases',
				type: 'number',
				valueGetter,
			},
			{
				field: 'latestStatus.data.weeklyLeasingStats1[0][\'Total Traffic\']',
				headerName: 'Total Traffic',
				type: 'number',
				valueGetter,
			},
			{
				field: 'latestStatus.data.weeklyLeasingStats1[0][\'Conversion Rate\']',
				headerName: 'Conversion Rate',
				valueGetter,
			},

			{
				field: 'latestStatus.data.weeklyLeasingStats2[0][\'Follow-up %\']',
				headerName: 'Follow-up %',
				valueGetter,
				// ...PERCENT_AS_STRING_FIELD_PROPS,
			},
			{
				field: 'latestStatus.data.weeklyLeasingStats2[0][\'Lease End\']',
				headerName: 'Lease End',
				valueGetter,
			},
			{
				field: 'latestStatus.data.weeklyLeasingStats2[0][\'100% Pace\']',
				headerName: '100% Pace',
				type: 'number',
				valueGetter,
			},
			{
				field: 'latestStatus.data.weeklyLeasingStats2[0][\'Prior 4 Wk Avg\']',
				// ref: 'latestStatus.data.weeklyLeasingStats2[0][\'Prior 4 Wk Average\']',
				headerName: 'Prior 4 Wk Avg',
				type: 'number',
				valueGetter,
			},
			{
				field: 'latestStatus.data.trending[0][\'Trending Effective Rate Growth\']',
				// ref: 'latestStatus.data.trending[0][\'Trending Rate\']',
				headerName: 'Trending Effective Rate Growth',
				...PERCENT_AS_STRING_FIELD_PROPS
			},
			{
				field: 'latestStatus.data.trending[0][\'Budgeted Rate\']',
				headerName: 'Budgeted Effective Rate',
				...PERCENT_AS_STRING_FIELD_PROPS
			},
			{
				field: 'latestStatus.data.trending[0][\'Delta vs. Budgeted\']',
				headerName: 'Delta Trending vs. Budgeted',
				...PERCENT_AS_STRING_FIELD_PROPS
			},

			{
				field: 'latestStatus.data.weeklyMarketShare[0][\'Natural %\']',
				headerName: 'Natural %',
				...PERCENT_AS_STRING_FIELD_PROPS
			},
			{
				// field: 'latestStatus.data.weeklyMarketShare[0][\'Wk Leases\']',
				field: 'latestStatus.data.weeklyMarketShare[0][\'CW(CH) subject\']',
				headerName: 'CW(CH) subject',
				valueGetter,
				type: 'number',
				minWidth: 100
			},
			{
				// field: 'latestStatus.data.weeklyMarketShare[0][\'Wk Market %\']',
				field: 'latestStatus.data.weeklyMarketShare[0][\'Wk% of Market\']',
				headerName: 'Wk% of Market',
				...PERCENT_AS_STRING_FIELD_PROPS
			},
			{
				// field: 'latestStatus.data.weeklyMarketShare[0][\'Delta to Comp %\']',
				field: 'latestStatus.data.weeklyMarketShare[0][\'Delta to Comp%\']',
				headerName: 'Delta to Comp %',
				...PERCENT_AS_STRING_FIELD_PROPS
			},

			/** Internal **/

			// {
			// 	field: 'latestStatus.data.teamCommentary',
			// 	headerName: 'Team Commentary',
			// 	...COMMENT_FIELD_PROPS
			// },
			{
				field: 'latestStatus.data.mcSupport',
				headerName: 'MC Support/Training',
				...COMMENT_FIELD_PROPS
			},
			// {
			// 	field: 'latestStatus.data.assetMarketingCommentary',
			// 	headerName: 'Asset/Marketing Commentary',
			// 	...COMMENT_FIELD_PROPS
			// },
			{
				field: 'latestStatus.data.rateCommentary',
				headerName: 'Rate/Special Commentary',
				...COMMENT_FIELD_PROPS
			},
			{
				field: 'latestStatus.data.rejectionFeedback',
				headerName: 'Rejection Feedback',
				...COMMENT_FIELD_PROPS
			},
			{
				field: 'latestStatus.data.staffingComment',
				headerName: 'Staffing Commentary',
				...COMMENT_FIELD_PROPS
			},
			{
				field: 'latestStatus.data.successes',
				headerName: 'Successes',
				...COMMENT_FIELD_PROPS
			},
			{
				field: 'latestStatus.data.challenges',
				headerName: 'Challenges',
				...COMMENT_FIELD_PROPS
			},
			{
				field: 'latestStatus.data.overallPerformance',
				headerName: 'Overall Performance',
				...COMMENT_FIELD_PROPS
			},
			{
				field: 'latestStatus.data.actionItemUpdates',
				headerName: 'Action Item Updates',
				...COMMENT_FIELD_PROPS
			},
			{
				field: 'latestStatus.data.newActionItems',
				headerName: 'New Action Items',
				...COMMENT_FIELD_PROPS
			},
			{
				field: 'latestStatus.data.marketInsightInternal',
				headerName: intl.formatMessage( { id: 'latestStatus.data.marketInsightInternal', defaultMessage: 'Market Insight' }),
				description: 'Market Insight (Internal)',
				...COMMENT_FIELD_PROPS
			},
			{
				field: 'latestStatus.data.keyDates',
				headerName: intl.formatMessage( { id: 'latestStatus.data.keyDates', defaultMessage: 'Key Dates & Market Outreach' }),
				description: 'Internal Key Dates & Market Outreach (Internal)',
				...COMMENT_FIELD_PROPS
			},

			/** External Fields **/
			{
				field: 'latestStatus.data.status',
				headerName: 'Follow Up Items & Status',
				description: 'Follow Up Items & Status (External)',
				...COMMENT_FIELD_PROPS
			},
			{
				field: 'latestStatus.data.marketInsight',
				headerName: intl.formatMessage( { id: 'latestStatus.data.marketInsight', defaultMessage: 'Market Insight' }),
				description: 'Market Insight (External)',
				...COMMENT_FIELD_PROPS
			},
			{
				field: 'latestStatus.data.marketOutreach',
				headerName: intl.formatMessage( { id: 'latestStatus.data.marketOutreach', defaultMessage: 'Key Dates & Market Outreach' }),
				description: 'Key Dates & Market Outreach (External)',
				...COMMENT_FIELD_PROPS
			},
			{
				field: 'latestStatus.data.currentConcessions',
				headerName: 'Current Concessions',
				description: 'Current Concessions (External)',
				...COMMENT_FIELD_PROPS
			},
			{
				field: 'latestStatus.data.leasingRecommendation',
				headerName: 'Recommendation',
				description: 'Leasing Recommendation (External)',
				...COMMENT_FIELD_PROPS
			},
			{
				field: 'latestStatus.data.operationsInsight',
				headerName: 'Facilities, Maintenance & Operations',
				description: 'Facilities, Maintenance & Operations (External)',
				...COMMENT_FIELD_PROPS
			},

			/** Director Input **/
			{
				field: 'latestStatus.data.strategy',
				headerName: 'Performance & Strategy',
				...COMMENT_FIELD_PROPS
			},
			{
				field: 'latestStatus.data.personnel',
				headerName: 'Personnel Concerns',
				...COMMENT_FIELD_PROPS
			},

			/** Audit Info **/
			{
				field: 'latestStatus.createdAt', headerName: 'Created', width: 160, type: 'date',
				description: 'Status Created Date',
				valueFormatter: formatDateTime,
				valueGetter
			},
			{
				field: 'latestStatus.createdBy', headerName: 'Created By', minWidth: 200, type: 'user',
				description: 'Status Created By',
				valueGetter,
				valueFormatter: formatUser
			},
			{
				field: 'latestStatus.updatedAt', headerName: 'Updated', width: 160, type: 'date',
				description: 'Status Last Updated Date',
				valueFormatter: formatDateTime,
				valueGetter,
			},
			{
				field: 'latestStatus.updatedBy', headerName: 'Updated By', minWidth: 200, type: 'user',
				description: 'Status Last Updated By',
				valueGetter,
				valueFormatter: formatUser
			},
		].map( ( column: any ) => {
			return {
				...column,
				minWidth: column.minWidth ?? 100
			};
		});
	}, [defaultColumnTypes, formatDateTime, intl, valueGetter, watchlist?.config?.reasons, watchlist?.config?.states]);
}
export default useWatchlistPropertiesGridColumns;
