import {
	TooltipProps
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import ContactCard from './ContactCard';
import { Contact } from './User/types';
import React from 'react';

interface ContactCardTooltipProps extends Omit<TooltipProps,'title'> {
	contact: Contact
}
export const ContactCardTooltip: React.FC<ContactCardTooltipProps> = ({contact, children, ...props}) => {

	return (
		<Tooltip
			{ ...props }
			title={ <div style={ { padding: 0, margin: 0 } }>
				<ContactCard contact={ contact } /></div> }
			componentsProps={ {
				tooltip: {
					sx: {
						backgroundColor: 'transparent', // Remove background to match PersonCard
						padding: 0, // Remove padding around the card
					},
				},
				popper: {
					sx: {
						zIndex: 1300, // Ensure the tooltip stays above other UI components
						maxWidth: 'none', // Allow the PersonCard to define its own width
					},
				},
			} }
		>
			{ children }
		</Tooltip>
	);
};
