
import { IMsalContext } from '@azure/msal-react';
import { LookerEmbedSDK } from '@looker/embed-sdk';

import { getTokenRequest } from '../../utils/authConfig';
export const useLooker = async ( msalContext: IMsalContext ) => {
		console.info('Initializing LookerEmbedSDK');
		const { instance, accounts } = msalContext;
		const lookerHost = window.env.lookerHost;

		return instance.acquireTokenSilent( getTokenRequest( accounts ) )
			.then( result => result.accessToken )
			.then( token => {

				LookerEmbedSDK.initCookieless(lookerHost, {
						url: '/api/reporting/acquire-embed-session',
						headers: {
							'Content-Type': 'application/json',
							'Authorization': `Bearer ${ token }`
						},
					},
					{
						url: '/api/reporting/generate-embed-tokens',
						method: 'PUT',
						headers: {
							'Content-Type': 'application/json',
							'Authorization': `Bearer ${ token }`
						}
					},
				);

				return LookerEmbedSDK;
			})
	}

