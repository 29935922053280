import { useMsal } from '@azure/msal-react';

import React from 'react';

import {
	Button,
	Paper
} from '@mui/material';
import { loginRequest } from '../../utils/authConfig';

export const SignIn = ({loginType = 'popup'}) => {

	const { instance } =useMsal();

	const handleLogin = ()=>{
		console.log('handleLogin:', loginType, instance);
		if (loginType === 'popup') {
			instance.loginPopup(loginRequest).catch(e => {
				console.log(e);
			});
		}
		else if (loginType === 'redirect') {
			instance.loginRedirect(loginRequest).catch(e => {
				console.log(e);
			});
		}
	};


	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					height: '40%',
				}}
			>
				<Paper
					elevation={0}
					style={{
						paddingTop: '100px',
						height: '300px',
						width: '400px',
						position: 'relative',
						borderRadius: 18,
						display: 'flex',
						justifyContent: 'flex-start',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Button onClick={() => handleLogin()}>Sign in</Button>
					<img src={`${process.env.PUBLIC_URL}/coreio_logo.png`} alt='COREio Logo' />
				</Paper>
			</div>
		</>
	);
};


