import {
	Person,
} from '@microsoft/mgt-react';
import {
	AvatarProps
} from '@mui/material';
import React from 'react';

import { Contact } from 'components/User/types'

interface ProfileImageProps extends AvatarProps {
	user: Contact;
}

const ProfileImage: React.FC<ProfileImageProps> = ( props ) => {
	console.dir(props);
	const { user } = props;

	return user.externalId && <Person
		userId={user.externalId}
		// verticalLayout={true}
		// view={"oneline"}
		// line1Property={"profilePhoto"}
		// avatarSize={"auto"}
	/>
};

export default ProfileImage;
