import { useMsal } from '@azure/msal-react';
import {
	Autocomplete,
	debounce,
	TextField,
} from '@mui/material';

import Grid from '@mui/material/Grid';
import React, {
	useEffect,
	useMemo,
	useState
} from 'react';
import { getApiProvider } from './hooks/API';

import { Contact } from './User/types';
import { Person } from '@microsoft/mgt-react';

export type MsGraphSearchProps = {
	id: string;
	onChange: ( event: any, value: Contact | null | undefined, reason: string ) => Promise<void> | void;
	label?: string;
	value?: Contact|null;
	disableSuggestions?: boolean
}

const MsGraphSearch: React.FC<MsGraphSearchProps> = ( props ) => {
	const msalContext = useMsal();
	const onChange = props.onChange;
	const [ options, setOptions ] = useState<Contact[]>( [] );
	const [ value, setValue ] = useState<Contact|null|undefined>( props.value );

	const find = ( query?: string ) => {
		if ( !query ) {
			return;
		}
		getApiProvider( msalContext )
			.then( api => api.getAll( `/people/${ query }` )
			                 .then( result => {
				                 setOptions( result?.data || [] );
			                 } )
			                 .catch( error => {
				                 setOptions( [] );
				                 console.error( error );
			                 } ) );
	};

	const debouncedFind = useMemo( () => debounce( find, 300 ), [] );

	const handleChange = async ( event: React.SyntheticEvent, newValue: Contact|null, reason: string ) => {
		if ( !reason ) {
			return;
		}
		await onChange( event, newValue, reason );
		setValue( newValue );
	};

	return ( <Autocomplete<Contact>
		{ ...props }
		value={ value }
		onChange={ handleChange }
		filterOptions={ ( x ) => x }
		options={ options }
		getOptionLabel={ ( option ) => option.name ? `${ option.name }` + ( option.title ? ` - ${ option.title }` : '' ) : '' }
		onInputChange={ ( event, value, reason ) => {
			if ( reason === 'input' ) {
				debouncedFind(value);
			}
		} }

		renderInput={ ( params ) => (
			<TextField { ...props } { ...params } onChange={ undefined } label={ props.label }/>
		) }

		isOptionEqualToValue={ ( option, value ) => {
			return option?.externalId === ( value?.externalId );
		} }

		renderOption={ ( props, option ) => {
			return (
				<li { ...props }>

					<Grid container alignItems="center">
					<Person
						userId={option.externalId}
						avatarSize={'small'}
						view={'twolines'} />
					</Grid>
				</li>
			);
		} }
/> );

};


export default MsGraphSearch;
