import Joi from 'joi';
import { PropertySchema } from '../property/update.js';

const property = Joi.object({
	id: Joi.number().optional().label('Community Id'),
	name: Joi.string()
		.optional()
		.label('Community Name'),
	externalPropertyId: Joi.number()
		.optional()
		.allow(null)
		.label('Entrata Id'),
}).concat(PropertySchema).optional().label('Community');

import {DocsUri, MapUri} from '../links.js';
export const UpdateProjectSchema = Joi.object({
	id: Joi.any()
		.required()
		.label('Id'),
	name: Joi.string()
		.optional()
		.disallow('')
		.label('Name'),
	alias: Joi.string()
		.optional()
		.allow('')
		.label('Nickname'),
	description: Joi.string()
		.optional()
		.allow('')
		.label('Description')
		.max(200),
	headline: Joi.string()
		.optional()
		.allow('')
		.label('Headline'),
	mapLink: MapUri.label('Map Link'),
	storyboardLink: DocsUri.label('Storyboard Link'),
	strategy: Joi.string()
		.optional()
		.allow('')
		.label('Strategy'),
	riskLevel: Joi.string()
		.allow('low','medium', 'high')
		.default('low')
		.label('Risk Level'),
	risks: Joi.array()
		.optional()
		.label('Risks'),
	phaseId: Joi.number().optional().label('Phase'),
	phase: Joi.object({
		id: Joi.number().required().label('Id'),
		name: Joi.string().optional().label('Name'),
		code: Joi.string().optional().label('Code'),
		index: Joi.number().optional().label('Index')
	}).optional().label('Phase'),
	deliveryDate: Joi.date()
		.optional()
		.label('Delivery Date'),
	buildingCount: Joi.number()
		.optional()
		.label('Buildings'),
	unitCount: Joi.number()
		.optional()
		.label('Units'),
	bedCount: Joi.number()
		.optional()
		.label('Beds'),
	parkingSpaceCount: Joi.number()
		.optional()
		.label('Parking Spaces'),
	grossArea: Joi.number()
		.optional()
		.label('Gross SF'),
	residentialArea: Joi.number()
		.optional()
		.label('Residential SF'),
	retailArea: Joi.number()
		.optional()
		.label('Retail SF'),
	property: property,
	totalValue: Joi.number()
		.optional()
		.label('Total Value'),
	projectedCost: Joi.number()
		.optional()
		.label('Projected Cost'),
	actualCost: Joi.number()
		.optional()
		.label('Actual Cost'),
	projectBudget: Joi.number()
		.optional()
		.label('Project Budget'),
	approvedProjectBudget: Joi.number()
		.optional()
		.label('Approved Project Budget'),
	projectSpent: Joi.number()
		.optional()
		.label('Project Spent'),
	pursuitBudget: Joi.number()
		.optional()
		.label('Pursuit Budget'),
	proformaHardCost: Joi.number()
		.optional()
		.label('PF HC Budget'),
	targetHardCost: Joi.number()
		.optional()
		.label('Target HC Budget'),
	gcHardCost: Joi.number()
		.optional()
		.label('GC HC Budget'),
	hardCostDelta: Joi.number()
		.optional()
		.label('Budget Delta'),
	leveredIrr: Joi.number()
		.optional()
		.label('Levered IRR'),
	unleveredIrr: Joi.number()
		.optional()
		.label('Unlevered IRR'),
	trendedYield: Joi.number()
		.optional()
		.label('Trended Yield'),
	untrendedYield: Joi.number()
		.optional()
		.label('Untrended Yield'),
	financialDataNote: Joi.string()
		.optional()
		.label('Note'),
	architects: Joi.array().optional()
		.allow(null).label('Architects'),
	lenders: Joi.array().optional()
		.allow(null).label('Lenders'),
	generalContractors: Joi.array().optional()
		.allow(null).label('General Contractors'),
	interiorDesigners: Joi.array().optional()
		.allow(null).label('Interior Designers'),
	developers: Joi.array().optional()
		.allow(null).label('Co-developers'),
	projectContacts: Joi.optional()
		.allow(null, Joi.object()).label('Contacts')
})
	.required()
	.label('Project');
