export const ProjectTypes = [
	{
		'code': 'A',
		'name': 'Acquisition'
	},
	{
		'code': 'G',
		'name': 'Ground Up'
	},
	{
		'code': 'V',
		'name': 'Value-add Acquisition'
	}
];