
import React, { useCallback, useState } from 'react';
import Chip from '@mui/material/Chip';
import ProfileImage from 'components/ProfileImage';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { AvatarGroup } from '@mui/material';
import { SxProps, Theme } from '@mui/material';
import { Contact } from 'components/User/types';
import { ContactCardTooltip } from '../ContactCardTooltip';

interface ContactAvatarGridCellProps {
  params: GridRenderCellParams;
  avatarSx: SxProps<Theme> | undefined
}



const ContactAvatarGridCell: React.FC<ContactAvatarGridCellProps> = ({ params,avatarSx = { width: 24, height: 24 } }) => {
	const contacts = params?.value as Contact[];
	const [expandedAvatar, setExpandedAvatar] = useState(0);
	const handleAvatarClick = useCallback((index: number = 0) => {
		let newValue = index !== expandedAvatar ? index : - 1;
		setExpandedAvatar(newValue);
	}, [expandedAvatar]);

	return (
		<span>
			<AvatarGroup key={`${params.row.id}-avatar-group`} max={4} sx={{ maxHeight: 24 }}>
				{contacts.map((contact, index) => (
					index === expandedAvatar ? <ContactCardTooltip contact={contact} placement="top">
						<Chip
							key={contact.id}
							label={contact.name}
							avatar={<ProfileImage alt={contact.name} user={contact} sx={avatarSx}/>}
							style={{ marginRight: 4 }}
							onClick={()=>handleAvatarClick(index)}
						/>
					</ContactCardTooltip> :
						<ContactCardTooltip contact={contact} placement="top">
							<ProfileImage
								sx={avatarSx}
								key={contact.id}
								alt={contact.name}
								user={contact}
								onClick={(e)=>handleAvatarClick(index)}
								style={{ cursor: 'pointer' }}
							/>
						</ContactCardTooltip>
				))}
			</AvatarGroup>
		</span>
	);
};

export default ContactAvatarGridCell;
