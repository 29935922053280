import { useIsAuthenticated } from '@azure/msal-react';
import * as React from 'react';
import {
	DataGridPremium,
	getGridDefaultColumnTypes, GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
	GridActionsCellItem, GridEditModes,
	GridRowModes,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton,
	useGridApiRef
} from '@mui/x-data-grid-premium';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ErrorDisplay from 'components/ErrorDisplay';
import { useSnackbar } from 'notistack';
import { Refresh } from '@mui/icons-material';
import PageTitle from 'components/hooks/PageTitle';
import useLocalStorage from 'components/hooks/LocalStorage';
import { NamedObjectFilter } from 'components/grid/GridColumnFilterWrapper';
import { NamedObjectComparator } from 'components/grid/GridColumnComparator';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIamApi } from 'services/iam-api';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getModifiedValues } from '../../utils';
import MsGraphSearch from '../MsGraphSearch';

const defaultSortModel=[{ field: 'ability', sort: 'asc' }];
const defaultFilterModel={ items:[] };
const defaultColumnVisibilityModel={
	id: false,
	ability: false,
	subject: true,
	action: true,
	field: true,
	actions: true
};
const defaultColumnTypes = getGridDefaultColumnTypes();

export default function IAMRulesGrid() {
	PageTitle({ id: 'iamRules', defaultTitle: 'Rules' });
	const isAuthenticated = useIsAuthenticated();
	const [rows, setRows] = useState([]);
	const [ rowModesModel, setRowModesModel ] = useState( {} );
	const [user, setUser] = React.useState();
	const [refresh, setRefresh] = useState(true);
	const handleError = ErrorDisplay();
	const api = useIamApi(handleError);
	const gridApiRef = useGridApiRef();
	const { enqueueSnackbar } = useSnackbar();
	const intl = useIntl();
	const {getRules, saveRule, removeRule, isLoading } = api;
	const [gridState, setGridState] = useLocalStorage(
		'iamrule-grid-model',
		{
			columns: {
				columnVisibilityModel: defaultColumnVisibilityModel,
				dimensions: {[GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD]: {maxWidth: -1, minWidth: 300, width: 300}},
				orderedFields: [GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD]
			},
			sorting: { sortModel: defaultSortModel },
			rowGrouping: { model: ['ability']},
			filtering: { filterModel: defaultFilterModel },
			pinnedColumns:{
				right: ['actions'],
				left: [GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD]
			}
		});
	const ability = {
		can: (action, subject) => false// useAbility(AbilityContext);}
	};

	useEffect(()=>{
		if (isAuthenticated) {
			setRefresh(true);
		}
	}, [isAuthenticated, user]);
	useEffect(() => {
		if (refresh) {
			setRefresh(false);
			getRules({params: {user: user?.email }})
				.then((data) => {
					setRows(data || []);
				});
		}
	}, [refresh, api]);

	const handleProcessRowUpdateError = React.useCallback((error) => {
		enqueueSnackbar(error.message, {
			variant: 'error',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center'
			}});
	}, []);

	const processRowUpdate = useCallback(
		async (newRow, oldRow) => {
			const data = oldRow.id ? getModifiedValues(newRow, oldRow) : newRow;
			const response = await saveRule(data);
			if (response?.id) {
				enqueueSnackbar('Saved!', {
					variant: 'success',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center'
					}
				});
				setRefresh(true);
			}
		},
		[saveRule],
	);

	const handleClickAdd = async () => {
		setRows([...[{id:'_new'}], ...rows ]);
		setRowModesModel( {
			...rowModesModel,
			'_new': {
				mode: GridRowModes.Edit
			}
		} );
	};

	const deleteRow = React.useCallback((params) => async () => {
		try
		{
			const data = await removeRule(params.row.id);
			if (data && data.deleted)
			{
				setRefresh(true);
				enqueueSnackbar(`Deleted ${ data.deleted || 0 } rules`, {
					variant: 'info',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center'
					}
				});
			}
		} catch (e)
		{
			await handleError(e);
		}
	},
	[enqueueSnackbar, handleError, removeRule]
	);
	const columns = useMemo(()=>[
		{ field: 'id', headerName: 'Id'},
		{ field: 'ability', headerName: 'Role',
			description: 'Grouping of related permissions/rules',
			valueFormatter: (params) => params.value?.name,
			groupingValueGetter: (params) => params.value?.name,
			filterOperators: defaultColumnTypes['string'].filterOperators?.map(o=>NamedObjectFilter(o)),
			sortComparator: NamedObjectComparator,
			groupable: true,
			flex:1
		},
		{ field: 'subject', headerName: 'Subject', minWidth: 10, description: 'Subject entity', groupable: true, flex: 1,
			valueOptions:[
				'Property',
				'Project',
				'Admin',
				'Accounting',
				'UIGridView',
				'IAM',
				'IAMUser',
				'Activity',
			]
		},
		{ field: 'action', headerName: 'Actions', minWidth: 10, description: 'Permissible action to be performed on entity', flex: 1 },
		{ field: 'field', headerName: 'Field', description: 'Field on Subject entity to apply rule', flex:1	},
		{
			field: 'actions',
			type: 'actions',
			description: 'Actions',
			maxWidth: 20,
			minWidth: 20,
			width: 20,
			getActions: ((params) => {
				return params.row?.id ? [
					<GridActionsCellItem key='editAction' icon={ <EditIcon /> } /*onClick={ editRow(params) }*/ label="Edit" showInMenu disabled={ !ability.can('update', 'IAM') } />,
					// <GridActionsCellItem key='copyAction' icon={ <CopyAll /> } onClick={ copyRow(params) } label="Copy" showInMenu disabled={ !ability.can('create', 'IAM') } />,
					<GridActionsCellItem key='deleteAction' icon={ <DeleteIcon /> } onClick={ deleteRow(params) } label="Delete" showInMenu disabled={ !ability.can('delete', 'IAM') } />
				] : [];
			}),
			editable: false, groupable: false, aggregable: false, filterable: false, disableExport: true
		}],[isAuthenticated]);
	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<Button
					size="small"
					startIcon={ <AddIcon /> }
					onClick={ handleClickAdd }
					disabled={ !ability.can('create', 'IAM') }
					title={ intl.formatMessage({ id: 'add_iam_rule', defaultMessage: 'Add Rule' }) }
				>
					{ intl.formatMessage({ id: 'add', defaultMessage: 'Add' }) }
				</Button>
				<Button
					size="small"
					startIcon={ <Refresh /> }
					onClick={ () =>
					{
						console.log('refresh');
						setRefresh(true);
					} }
					title={ intl.formatMessage({ id: 'refresh-grid', defaultMessage: 'Refresh Data' }) }
				>
					{ intl.formatMessage({ id: 'refresh', defaultMessage: 'Refresh' }) }
				</Button>
				<GridToolbarColumnsButton/>
				<GridToolbarFilterButton/>
				<GridToolbarExport />
				{/*<MsGraphSearch sx={{ width: 200}}*/}
				{/*               value={user}*/}
				{/*               onChange={(event, newValue) => {*/}
				{/*		setUser(newValue);*/}
				{/*	}}*/}
				{/*/>*/}
			</GridToolbarContainer>
		);
	}

	return (
		<div style={ { height: '99%', width: '99%', alignContent: 'space-around' } }>
			<DataGridPremium
				apiRef={ gridApiRef }
				rows={ rows }
				columns={ columns }
				pagination
				disableAggregation={ true }
				initialState={gridState}
				defaultGroupingExpansionDepth={2}
				onStateChange={(state)=>setGridState(state)}
				editMode={ GridEditModes.Row }
				//isCellEditable={(params)=>true}
				//sortingOrder={ ['desc', 'asc'] }
				slots={ { toolbar: CustomToolbar, loading: isLoading } }
				processRowUpdate={processRowUpdate}
				onProcessRowUpdateError={handleProcessRowUpdateError}
				rowModesModel={ rowModesModel }
				onRowModesModelChange={ ( newModel ) => setRowModesModel( newModel ) }
				disableMultipleRowSelection={ true }
			/>
		</div>
	);
}
