import Joi from 'joi';
import { Groups } from '../../constants/groups.js';
import { ProjectTypes } from '../../constants/project-types.js';
import { States } from '../../constants/states.js';
import {tlds} from '@hapi/tlds';

export const CreateProjectSchema = Joi.object({
	id: Joi.any()
		.optional()
		.label('Id'),
	groupCode: Joi.string()
		.required()
		.label('Group')
		.allow(...Groups.map(v => v.code)),
	projectTypeCode: Joi.string()
		.required()
		.label('Project Type')
		.allow(...ProjectTypes.map(v => v.code)),
	stateCode: Joi.string()
		.required()
		.label('State')
		.allow(...States.map(v => v.code)),
	name: Joi.string()
		.required()
		.disallow('')
		.label('Name'),
	alias: Joi.string()
		.optional()
		.allow('')
		.label('Nickname'),
	description: Joi.string()
		.optional()
		.allow('')
		.label('Description')
		.max(200),
	headline: Joi.string()
		.optional()
		.allow('')
		.label('Headline'),
	mapLink: Joi.string()
		.optional()
		.allow('')
		.uri({
			scheme: [
				'https',
			],
			domain: {
				tlds: {
					allow: tlds
				}
			}
		})
		.regex(/\/\/google.com\/maps|\/\/www.google.com\/maps|\/\/goo.gl\/maps\//)
		.label('Map Link'),
	storyboardLink: Joi.string()
		.optional()
		.allow('')
		.uri({
			scheme: [
				'https',
			],
			domain: {
				tlds: {
					allow: tlds
				}
			}
		})
		.regex(/\/\/docs.google.com/)
		.label('Storyboard Link'),
	strategy: Joi.string()
		.optional()
		.allow('')
		.label('Strategy'),
	riskLevel: Joi.string()
		.allow('low','medium', 'high')
		.default('low')
		.label('Risk Level'),
	risks: Joi.array()
		.optional()
		.label('Risks'),
	deliveryDate: Joi.date()
		.optional()
		.label('Delivery Date'),
	buildingCount: Joi.number()
		.optional()
		.label('Buildings'),
	unitCount: Joi.number()
		.optional()
		.label('Units'),
	bedCount: Joi.number()
		.optional()
		.label('Beds'),
	parkingSpaceCount: Joi.number()
		.optional()
		.label('Parking Spaces'),
	grossArea: Joi.number()
		.optional()
		.label('Gross SF'),
	residentialArea: Joi.number()
		.optional()
		.label('Residential SF'),
	retailArea: Joi.number()
		.optional()
		.label('Retail SF'),
	property: Joi.object({
		id: Joi.number()
			.required()
			.label('Community'),
		name: Joi.string()
			.optional()
			.label('Community Name'),
		externalPropertyId: Joi.number()
			.optional()
			.allow(null)
			.label('Entrata Id')
	}, 'Please select a Community').required().label('Community'),
	totalValue: Joi.number()
		.optional()
		.label('Total Value'),
	projectedCost: Joi.number()
		.optional()
		.label('Projected Cost'),
	actualCost: Joi.number()
		.optional()
		.label('Actual Cost'),
	projectBudget: Joi.number()
		.optional()
		.label('Project Budget'),
	approvedProjectBudget: Joi.number()
		.optional()
		.label('Approved Project Budget'),
	projectSpent: Joi.number()
		.optional()
		.label('Project Spent'),
	pursuitBudget: Joi.number()
		.optional()
		.label('Pursuit Budget'),
	proformaHardCost: Joi.number()
		.optional()
		.label('PF HC Budget'),
	targetHardCost: Joi.number()
		.optional()
		.label('Target HC Budget'),
	gcHardCost: Joi.number()
		.optional()
		.label('GC HC Budget'),
	hardCostDelta: Joi.number()
		.optional()
		.label('Budget Delta'),
	leveredIrr: Joi.number()
		.optional()
		.label('Levered IRR'),
	unleveredIrr: Joi.number()
		.optional()
		.label('Unlevered IRR'),
	trendedYield: Joi.number()
		.optional()
		.label('Trended Yield'),
	untrendedYield: Joi.number()
		.optional()
		.label('Untrended Yield'),
	financialDataNote: Joi.string()
		.optional()
		.label('Note'),
	architects: Joi.array().optional()
		.allow(null).label('Architects'),
	lenders: Joi.array().optional()
		.allow(null).label('Lenders'),
	generalContractors: Joi.array().optional()
		.allow(null).label('General Contractors'),
	interiorDesigners: Joi.array().optional()
		.allow(null).label('Interior Designers'),
	developers: Joi.array().optional()
		.allow(null).label('Co-developers'),
	projectContacts: Joi.optional()
		.allow(null, Joi.object()).label('Contacts')
})
	.required()
	.label('Project');
